@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
body {
    font-family: 'Open Sans', sans-serif;
}

/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the IbanElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=iban#elements_create-options-classes
*/

.StripeElement {
    border-radius: 4px;
    padding: 8px;
    border: solid 2px #eeeeee;
    height: 38px;
    width: 100%;
    font-size: 1.4rem;
    color: #434343;
    transition: all 0.3s;
    line-height: 38px;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #b54025;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

